import { Input } from 'antd';
import { ResponseDataType, postRequest } from 'api';
import { EndStreamType } from 'constant/enum';
import { toast } from 'react-toastify';
import { modalConfirm } from 'utils';

interface UpdateApproveTaskValueParamType {
  value: number;
  streamId: string;
  endStream?: boolean;
  title: string;
  currentOrg?: string;
  endType?: string;
}

export const updateApproveTaskValue = (
  params: UpdateApproveTaskValueParamType
) => {
  const { value, streamId, endStream, title, currentOrg, endType } = params;

  modalConfirm({
    title,
    onOk: async () => {
      const denialReason = (
        document.getElementById('denial-reason') as HTMLInputElement
      )?.value;
      if (endStream && (!denialReason || denialReason === '')) {
        toast.error('Cần nhập lý do từ chối!');
        return;
      }

      let apiRoute: string;
      if (endStream) {
        if (endType === EndStreamType.CANCEL) {
          apiRoute = 'cancel-task-for-ceo';
        } else apiRoute = 'update-task-value-and-end-stream';
      } else apiRoute = 'update-task-value';

      const response: ResponseDataType<string> = await postRequest(
        `/stream/${apiRoute}`,
        {
          organizationId: currentOrg,
          streamId,
          optionValue: value,
          reason: denialReason || null,
        }
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        toast.success('Cập nhật thành công!');
      }
    },
    content: endStream && (
      <Input.TextArea
        autoSize
        id='denial-reason'
        placeholder='Nhập lý do từ chối'
        style={{ margin: '10px 0 20px -20px', fontSize: 18, padding: 10 }}
      />
    ),
  });
};

export const completeEndStreamTask = (
  params: UpdateApproveTaskValueParamType
) => {
  const { value, streamId, title, currentOrg } = params;

  modalConfirm({
    title,
    onOk: async () => {
      const response: ResponseDataType<string> = await postRequest(
        '/stream/complete-end-stream-task',
        {
          organizationId: currentOrg,
          streamId,
          optionValue: value,
        }
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        toast.success('Cập nhật thành công!');
      }
    },
  });
};
