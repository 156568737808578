export enum DepartmentNamecode {
  CEO = 'CEO',
  CFO = 'CFO',
  KTT = 'KTT',
  KTNB = 'KTNB',
  KTNBTA = 'KTNBTA',
  HCNS = 'HCNS',
  QC = 'QC',
}

export const specialDepartment = [
  DepartmentNamecode.KTNB,
  DepartmentNamecode.KTNBTA,
];

export const departmentInfo = {
  [DepartmentNamecode.CEO]: 'CEO',
  [DepartmentNamecode.CFO]: 'CFO',
  [DepartmentNamecode.KTT]: 'Kế toán trưởng',
};
