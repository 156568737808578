import Linkify from 'linkify-react';
import React, { BaseHTMLAttributes } from 'react';

interface MultiLinePropsType extends BaseHTMLAttributes<HTMLDivElement> {
  text: string;
}

export const MultiLineText = (props: MultiLinePropsType) => {
  const { text, ...rest } = props;
  return (
    <div {...rest}>
      <Linkify options={{ target: '_blank' }}>
        {!text || text === '' ? (
          <></>
        ) : (
          text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))
        )}
      </Linkify>
    </div>
  );
};
