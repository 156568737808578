import { Link } from 'react-router-dom';

import styles from '../layout.module.css';
import { OrgLogo } from 'Components';
import { useAppContext } from 'Context/AppContext';
import {
  AccountDropdown,
  ChatNotiDropdown,
  NotificationDropdown,
  ServiceDropdown,
} from './Components';
import { ResponseDataType, getRequest } from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { NotiResType, NotificationInfo } from './type';

export default function Header() {
  const { currentOrg, currentOrgInfo } = useAppContext();
  const [notiList, setNotiList] = useState<NotificationInfo[]>([]);
  const [chatNotiList, setChatNotiList] = useState<NotificationInfo[]>([]);
  const [numberOfNotiNotSeen, setNumberOfNotiNotSeen] = useState(0);
  const [numberOfChatNotiNotSeen, setNumberOfChatNotiNotSeen] = useState(0);

  useEffect(() => {
    const getNotifications = async () => {
      if (!currentOrg) return;
      const res: ResponseDataType<NotiResType> = await getRequest(
        `/notification/get-notification?orgId=${currentOrg}`
      );

      if (res.code) {
        toast.error(res.message);
      } else {
        setNotiList(res.data?.notiList || []);
        setChatNotiList(res.data?.chatNotiList || []);
      }
    };

    getNotifications();
  }, [currentOrg]);

  return (
    <div className={styles.header}>
      <Link to='/' className={styles.organization}>
        <OrgLogo
          className={styles.organization_logo}
          src={currentOrgInfo?.logo}
        />
        {/* <p className={styles.organization_name}>Adot Workspace</p> */}
      </Link>

      <div className={styles.account}>
        <ServiceDropdown />
        {currentOrg && (
          <>
            <ChatNotiDropdown
              chatNotiList={chatNotiList}
              numberOfChatNotiNotSeen={numberOfChatNotiNotSeen}
              setChatNotiList={setChatNotiList}
              setNumberChatNotiOfNotSeen={setNumberOfChatNotiNotSeen}
            />
            <NotificationDropdown
              notiList={notiList}
              numberOfNotiNotSeen={numberOfNotiNotSeen}
              setNotiList={setNotiList}
              setNumberNotiOfNotSeen={setNumberOfNotiNotSeen}
            />
          </>
        )}
        <AccountDropdown />
      </div>
    </div>
  );
}
