import React, { createContext, useEffect, useRef, useState } from 'react';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { removeStorage } from 'utils';

export interface OrganizationInfo {
  id: string;
  name: string;
  logo: string;
  numberOfMembers: number;
  memberAvatar: string;
  memberId: string;
  memberDepartmentCode: string;
}

interface OrgListDto {
  orgId?: string;
  orgList: OrganizationInfo[];
  memberId?: string;
}

interface AppContextType {
  currentOrg: string | undefined;
  changeOrganization: (orgId?: string, memberId?: string) => void;
  orgList: OrganizationInfo[] | undefined;
  onEvent: (event: string, callback: (response: any) => void) => void;
  disconnectSocket: () => Promise<void>;
  seenChatNotiRootId: string | undefined;
  setSeenChatNotiRootId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  currentOrgInfo: OrganizationInfo | undefined;
}

const AppContext = createContext<AppContextType | null>(null);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentOrg, setCurrentOrg] = useState<string | undefined>();
  const [currentOrgInfo, setCurrentOrgInfo] = useState<OrganizationInfo>();
  const [orgList, setOrgList] = useState<OrganizationInfo[] | undefined>();
  const [seenChatNotiRootId, setSeenChatNotiRootId] = useState<string>();
  const navigate = useNavigate();

  const socket = useRef<Socket>();

  const connectSocket = (orgId?: string, memberId?: string) => {
    const accessToken = localStorage.getItem('accessToken');
    socket?.current?.disconnect();
    socket.current = io(process.env.REACT_APP_BASE_URL || '', {
      auth: {
        token: accessToken,
        organizationID: orgId,
        memberId,
      },
    });
  };

  const onEvent = (event: string, callback: (response: any) => void) => {
    if (!socket.current) return;

    socket.current.off(event);
    socket.current.on(event, async (response) => {
      await callback(await response);
    });
  };

  const disconnectSocket = async () => {
    socket.current?.disconnect();
  };

  const changeOrganization = (
    orgId?: string,
    memberId?: string,
    orgListFromRes?: OrganizationInfo[]
  ) => {
    setCurrentOrg(orgId);
    setCurrentOrgInfo(
      (orgListFromRes || orgList)?.find((org) => org.id === orgId)
    );
    connectSocket(orgId, memberId);
  };

  useEffect(() => {
    const getOrgList = async () => {
      const response: ResponseDataType<OrgListDto> = await getRequest(
        `/customer/get-org-list?customerId=${localStorage.getItem(
          'customerId'
        )}${
          localStorage.getItem('organizationId')
            ? `&orgId=${localStorage.getItem('organizationId')}`
            : ''
        }`
      );

      if (response.code) {
        toast.error(response?.message);
        disconnectSocket();
        getRequest('/auth/logout');
        removeStorage();
        navigate('/login');
        return;
      }

      if (response.data?.orgId) {
        localStorage.setItem('organizationId', response.data.orgId);
      }
      setOrgList(response.data?.orgList);
      changeOrganization(
        response.data?.orgId,
        response.data?.memberId,
        response.data?.orgList
      );
    };

    getOrgList();
    // eslint-disable-next-line
  }, []);

  const contextValue = {
    currentOrg,
    changeOrganization,
    orgList,
    onEvent,
    disconnectSocket,
    seenChatNotiRootId,
    setSeenChatNotiRootId,
    currentOrgInfo,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('AppContext is not available');
  }

  return context;
};
