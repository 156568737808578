import { EndStreamType } from 'constant/enum';
import { ActionPropType, StreamActionPropType } from '..';
import { updateApproveTaskValue } from './action';
import {
  ButtonBox,
  ButtonGreen,
  ButtonRed,
  DisableButtonGreenWrapper,
  DisableButtonRedWrapper,
  Space,
} from './style';
import { useAppContext } from 'Context/AppContext';
import { DepartmentNamecode } from 'constant/department';

const ApproveDenyAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt',
            currentOrg,
          })
        }
      >
        Duyệt
      </ButtonGreen>
      <Space />
      <ButtonRed
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: true,
            title: 'Xác nhận từ chối',
            currentOrg,
            endType: EndStreamType.REFUSE,
          })
        }
      >
        Từ chối
      </ButtonRed>
    </ButtonBox>
  );
};

export const ApproveTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã từ chối' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ApproveDenyAction {...rest} />;
};

const ConfirmAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg, currentOrgInfo } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt?',
            currentOrg,
          })
        }
      >
        Xác nhận
      </ButtonGreen>
      <Space />
      {currentOrgInfo?.memberDepartmentCode === DepartmentNamecode.CEO && (
        <ButtonRed
          onClick={() =>
            updateApproveTaskValue({
              value: 0,
              streamId,
              endStream: true,
              title: 'Xác nhận hủy',
              currentOrg,
              endType: EndStreamType.CANCEL,
            })
          }
        >
          Hủy
        </ButtonRed>
      )}
    </ButtonBox>
  );
};

export const ConfirmTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã hủy' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ConfirmAction {...rest} />;
};
