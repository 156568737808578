import { Image, Space } from 'antd';
import { AttachmentItemPropType, FileStatus } from './type';
import { EyeOutlined } from '@ant-design/icons';
import { ClearOutlined } from '@mui/icons-material';
import { useAppContext } from 'Context/AppContext';
import { useStreamDetailInfo } from '../../Context/StreamDetailContext';
import { ResponseDataType, postRequest } from 'api';
import { modalConfirm } from 'utils';
import { toast } from 'react-toastify';

interface ImageAttachmentItemPropTyps extends AttachmentItemPropType {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ImageAttachmentItem = (props: ImageAttachmentItemPropTyps) => {
  const {
    attachedFile,
    type,
    setAttachedFileList,
    updatePermission,
    setIsOpenModal,
  } = props;
  const { currentOrg } = useAppContext();
  const { selectedStreamId } = useStreamDetailInfo();

  const handleUpdateFileStatus = (fileId: string, status: number) => {
    setIsOpenModal(true);
    modalConfirm({
      title: 'Xác nhận cập nhật trạng thái ảnh!',
      onOk: async () => {
        const response: ResponseDataType<string> = await postRequest(
          `/${type}/update-file-status`,
          {
            organizationId: currentOrg,
            streamId: selectedStreamId,
            fileId,
            status,
          }
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Cập nhật trạng thái thành công');
          setAttachedFileList((prev) =>
            (prev || []).map((file) =>
              file.fileId === fileId ? { ...file, status } : file
            )
          );
        }
        setIsOpenModal(false);
      },
      onCancel: () => setIsOpenModal(false),
    });
  };

  return (
    <Image
      src={`${process.env.REACT_APP_BASE_URL}/api/web/files/attachment/${type}/${attachedFile.uuidName}?originalname=${attachedFile.originalName}`}
      width={150}
      height={150}
      style={{ objectFit: 'cover' }}
      preview={{
        mask: (
          <>
            <Space direction='horizontal' align='center'>
              <EyeOutlined />
              Xem ảnh
            </Space>
            {updatePermission && (
              <div
                style={{ position: 'absolute', top: 10, right: 10 }}
                onClick={() =>
                  handleUpdateFileStatus(
                    attachedFile.fileId,
                    attachedFile.status === FileStatus.USE
                      ? FileStatus.UNUSE
                      : FileStatus.USE
                  )
                }
              >
                <ClearOutlined />
              </div>
            )}
          </>
        ),
        onVisibleChange: (a, b) => {
          console.log(a, b);
        },
      }}
    />
  );
};
