import { FileStatus } from './type';

export const checkIsImage = (filename: string, status: number) => {
  if (status === FileStatus.UNUSE) return false;
  const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp',
    'ico',
  ];
  const extension = filename.split('.').pop()?.toLowerCase();
  return extension ? imageExtensions.includes(extension) : false;
};
