import styled from '@emotion/styled';
import { MemberFullInfo } from '../MemberInfo';
import { MultiLineText } from 'Components';
import { CommentInfoDataType } from './CommentBox';
import {
  AttachedFileInfo,
  AttachedFileList,
  AttachmentType,
} from '../AttachedFile';
import { useState } from 'react';

interface CommentItemPropsType extends CommentInfoDataType {
  customerId: string;
}

export const CommentItem = (props: CommentItemPropsType) => {
  const { sender, content, attachments, customerId } = props;
  const [attachedFileList, setAttachedFileList] = useState<
    AttachedFileInfo[] | undefined
  >(attachments);
  return (
    <div
      style={{
        margin: '20px 0',
        borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
        paddingBottom: '10px',
      }}
    >
      <MemberFullInfo {...sender} />
      <CommentText text={content} />
      <AttachedFileList
        attachedFileList={attachedFileList || []}
        type={AttachmentType.COMMENT}
        updatePermisstion={customerId === sender.customerId}
        setAttachedFileList={setAttachedFileList}
      />
    </div>
  );
};

const CommentText = styled(MultiLineText)`
  padding-top: 15px;
  font-weight: 600;
  font-size: 14px;
`;
