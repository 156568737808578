import { useEffect } from 'react';

import { getRequest } from 'api';
import { useNavigate } from 'react-router-dom';
import { removeStorage } from 'utils';

export const LoginNewAccount = () => {
  const navigate = useNavigate();

  useEffect(() => {
    try {
      getRequest('/auth/logout');
      removeStorage();
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  }, [navigate]);

  return <></>;
};
