import 'antd/dist/reset.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfigProvider } from 'antd';

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PRIVATE_ROUTER, PUBLIC_ROUTER } from 'router';
import MainLayout from 'Layout/MainLayout';
import { AppProvider } from 'Context/AppContext';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Quicksand',
          colorText: '#141D49',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          {PUBLIC_ROUTER.map((e) => (
            <Route key={e.key} path={e.path} element={e.element} />
          ))}
          {PRIVATE_ROUTER.map((e) => (
            <Route
              key={e.key}
              path={e.path}
              element={
                <AppProvider>
                  <MainLayout
                    navKey={e.navKey}
                    subNavKey={e.subNavKey}
                    subSubNavKey={e.subSubNavKey}
                  >
                    {e.element}
                  </MainLayout>
                </AppProvider>
              }
            />
          ))}
        </Routes>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
