import { MenuItemKey } from './const';

export interface DepartmentInfo {
  id: string;
  name: string;
  namecode: string;
}

export interface GroupInfo {
  id: string;
  name: string;
}

export interface MemberInfoInList {
  id: string;
  name: string;
  avatar: string;
}

export interface MenuItemType {
  key: MenuItemKey;
  icon: JSX.Element;
  label: string;
  children?: { key: string; label: string }[];
}

export interface MemberDetailInfoType {
  id: string;
  email: string;
  name: string;
  phone?: string;
  avatar?: string;
  department?: string;
  departmentNamecode?: string;
  role?: number;
  groupList?: string[];
  status: number;
}

export enum DepartmentRole {
  STAFF = 0,
  MANAGER = 1,
}
