import { Row } from 'antd';
import { MemberInfoDataType } from './type';
import { Avatar } from 'Components';
import { ImgHTMLAttributes } from 'react';
import { getFullInfoInDepartment } from 'utils';

interface CreatorFullInfoPropsType extends MemberInfoDataType {
  avatarProps?: ImgHTMLAttributes<HTMLImageElement>;
}

export const MemberFullInfo = (props: CreatorFullInfoPropsType) => {
  const {
    name,
    avatar,
    departmentCode,
    departmentName,
    role,
    avatarProps,
    isOldInfo,
  } = props;

  return (
    <Row align='middle'>
      <Avatar
        src={avatar}
        style={{ height: 25, width: 25, borderRadius: '50%' }}
        {...avatarProps}
      />
      <div style={{ marginLeft: 10, fontWeight: 400 }}>
        <span style={{ fontWeight: 700 }}>{name}</span>
        <br />
        {getFullInfoInDepartment({
          isOldInfo,
          isManager: !!role,
          department: {
            name: departmentName,
            namecode: departmentCode || '',
          },
        })}
      </div>
    </Row>
  );
};

export const MemberInfo = (member: MemberInfoDataType) => {
  const { name, departmentCode, departmentName, role, isOldInfo } = member;

  return (
    <>
      {name}
      {' ('}
      {getFullInfoInDepartment({
        isOldInfo,
        isManager: !!role,
        department: {
          name: departmentName,
          namecode: departmentCode || '',
        },
      })}
      {')'}
    </>
  );
};
