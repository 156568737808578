import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { Row } from 'antd';

import styles from './organization.module.css';
import { OrgLogo } from 'Components';

interface OrganizationItemProps {
  id: string;
  name: string;
  logo: string;
  numberOfMembers: number;
  isSelected: boolean;
  selectOrg: () => void;
}

export const OrganizationItem = (props: OrganizationItemProps) => {
  const { name, logo, numberOfMembers, isSelected, selectOrg } = props;

  return (
    <Row
      className={styles.org_item_wrapper}
      justify='space-between'
      onClick={selectOrg}
    >
      <Row>
        <OrgLogo className={styles.org_item_logo} src={logo} />
        <div>
          <div className={styles.org_item_name}>{name}</div>
          <div className={styles.org_item_num_of_mem}>
            Số thành viên:{' '}
            {numberOfMembers < 10 ? `0${numberOfMembers}` : numberOfMembers}
          </div>
        </div>
      </Row>
      {isSelected ? (
        <RadioButtonCheckedOutlinedIcon
          sx={{ marginLeft: 3, color: '#1D72D8' }}
        />
      ) : (
        <RadioButtonUncheckedOutlinedIcon
          sx={{ marginLeft: 3, color: '#1D72D8' }}
        />
      )}
    </Row>
  );
};
