export const menu_item_sx = {
  color: '#141D49',
  fontFamily: 'Quicksand',
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
};

export const menu_item_for_link_sx = {
  padding: 0,
  minWidth: '200px',
  fontFamily: 'Quicksand',
  fontWeight: '600',
};

export const circular_progress_sx = {
  color: '#141D49',
};
