import styled from '@emotion/styled';
import { BaseLinePropType } from './type';

interface VerticalLinePropType extends BaseLinePropType {
  height?: string;
}

export const VerticalLine = styled.div((props: VerticalLinePropType) => {
  const { height = '100%', weight = '50', bgColor } = props;

  return `
    width: 1px;
    height: ${height};
    background-color: ${bgColor || '#141D49'}${weight};
  `;
});
