import styled from '@emotion/styled';
import {
  DeleteOutline,
  DownloadOutlined,
  RestorePageOutlined,
} from '@mui/icons-material';
import { useAppContext } from 'Context/AppContext';
import { Row } from 'antd';
import { ResponseDataType, postRequest } from 'api';
import { modalConfirm } from 'utils';
import { useStreamDetailInfo } from '../../Context/StreamDetailContext';
import { toast } from 'react-toastify';
import { AttachmentItemPropType, FileStatus, OpenFileType } from './type';

export const AttachmentItem = (props: AttachmentItemPropType) => {
  const { attachedFile, updatePermission, setAttachedFileList, type } = props;
  const { currentOrg } = useAppContext();
  const { selectedStreamId } = useStreamDetailInfo();

  const handleOpenFile = (
    originalName: string,
    uuidName: string,
    openType: OpenFileType
  ) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/api/web/files/attachment/${type}/${uuidName}?originalname=${originalName}&openType=${openType}`,
      '_blank'
    );
  };

  const handleUpdateFileStatus = (fileId: string, status: number) => {
    modalConfirm({
      title: 'Xác nhận cập nhật!',
      onOk: async () => {
        const response: ResponseDataType<string> = await postRequest(
          `/${type}/update-file-status`,
          {
            organizationId: currentOrg,
            streamId: selectedStreamId,
            fileId,
            status,
          }
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Cập nhật trạng thái thành công');
          setAttachedFileList((prev) =>
            (prev || []).map((file) =>
              file.fileId === fileId ? { ...file, status } : file
            )
          );
        }
      },
    });
  };

  return (
    <AttactFileItem justify='space-between' align='middle'>
      <div
        className='attachment-item-name'
        style={{
          textDecoration:
            attachedFile.status === FileStatus.USE ? 'unset' : 'line-through',
          cursor: 'pointer',
        }}
        onClick={() =>
          handleOpenFile(
            attachedFile.originalName,
            attachedFile.uuidName,
            OpenFileType.OPEN
          )
        }
      >
        {attachedFile.originalName}
      </div>
      <Row>
        <div
          onClick={() =>
            handleOpenFile(
              attachedFile.originalName,
              attachedFile.uuidName,
              OpenFileType.DOWNLOAD
            )
          }
        >
          <DownloadOutlined sx={{ color: '#267CDE', cursor: 'pointer' }} />
        </div>
        {updatePermission && (
          <div
            style={{ marginLeft: 5 }}
            onClick={() =>
              handleUpdateFileStatus(
                attachedFile.fileId,
                attachedFile.status === FileStatus.USE
                  ? FileStatus.UNUSE
                  : FileStatus.USE
              )
            }
          >
            {attachedFile.status === FileStatus.USE ? (
              <DeleteOutline sx={{ color: '#D04339', cursor: 'pointer' }} />
            ) : (
              <RestorePageOutlined
                sx={{ color: '#42B814', cursor: 'pointer' }}
              />
            )}
          </div>
        )}
      </Row>
    </AttactFileItem>
  );
};

const AttactFileItem = styled(Row)`
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 12px;
  margin-top: 10px;
  color: #141d49;
  font-weight: 700;
  .attachment-item-name:hover {
    color: green;
  }
`;
