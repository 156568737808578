import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Grid, Menu, MenuItem } from '@mui/material';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';

import styles from '../home.module.css';
import { card_sx, grid_item_sx } from './styled';
import { menu_item_for_link_sx, menu_item_sx } from 'constant/styled';

// const circular_progress_sx = {
//   color: "#141D49",
// };

export const Homeless = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.home_wrapper}>
      <Grid container spacing={5}>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Việc của tôi</p>
              <p className={styles.card_number}>0</p>
              <Grid container justifyContent='flex-end'>
                <GestureOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Dự án</p>
              <p className={styles.card_number}>0</p>
              <Grid container justifyContent='flex-end'>
                <BusinessCenterOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Đề xuất</p>
              <p className={styles.card_number}>0</p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Sự kiện</p>
              <p className={styles.card_number}>0</p>
              <Grid container justifyContent='flex-end'>
                <EventAvailableOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <div
        id='create-option'
        style={{
          borderRadius: '50%',
          position: 'fixed',
          left: '20px',
          bottom: '20px',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AddCircleIcon
          sx={{
            fontSize: '70px',
            cursor: 'pointer',
            color: '#141D49',

            '@media (max-width: 480px)': {
              fontSize: '50px',
            },
          }}
        />
      </div>
      <Menu
        // anchorPosition={}
        id='create-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        autoFocus={false}
        MenuListProps={{
          'aria-labelledby': 'create-option',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
            padding: '5px',
            marginTop: '-90px',
            border: 'solid 1px #cacaca',
            boxShadow: 'none',
          },
        }}
      >
        <MenuItem sx={menu_item_for_link_sx}>
          <Link className={styles.link_in_option_menu} to='/'>
            <AddIcon />
            &nbsp;Tạo việc
          </Link>
        </MenuItem>
        <div className={styles.line} />
        <MenuItem sx={menu_item_sx}>
          <AddIcon />
          &nbsp;Tạo dự án
        </MenuItem>
        <div className={styles.line} />
        <MenuItem sx={menu_item_sx}>
          <AddIcon />
          &nbsp;Tạo đề xuất
        </MenuItem>
        <div className={styles.line} />
        <MenuItem sx={menu_item_sx}>
          <AddIcon />
          &nbsp;Tạo sự kiện
        </MenuItem>
      </Menu>
    </div>
  );
};
