import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal } from 'antd';
import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import styles from './authen.module.css';
import { ResponseDataType, postRequest } from 'api';

interface RegisterDataType {
  email: string;
  id: string;
}

export const SignupPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    if (localStorage.getItem('email')) navigate('/');
  });

  const Signup = async (value: { email: string; password: string }) => {
    try {
      const response: ResponseDataType<RegisterDataType> = await postRequest(
        '/auth/register',
        {
          email: value.email,
          password: value.password,
        }
      );

      if (response.code) {
        if (typeof response.message === 'object') {
          response.message.forEach((message: string) => toast.error(message));
        } else toast.error(response.message);
      } else if (response.data?.id) {
        Modal.success({
          style: { fontFamily: 'Quicksand' },
          title: 'Thành công!',
          content: `Email kích hoạt tài khoản sẽ được gửi tới ${value.email} trong vài phút!`,
          onOk() {
            navigate('/login');
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.signup_page}>
      <div className={styles.signup_page_background} />
      <div className={styles.authen_wrapper}>
        <img className={styles.logo} src='/Images/logo.png' alt='' />
        <div className={styles.name}>Adotsystem</div>
        <Form form={form} onFinish={(value) => Signup(value)}>
          <div className={`${styles.form} authen-form`}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Cần nhập email!' },
                { type: 'email', message: 'Cần nhập email đúng định dạng!' },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={<MailOutlined className={styles.icon} />}
                placeholder='Email của bạn'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='password'
              rules={[
                { required: true, message: 'Cần nhập mật khẩu!' },
                { min: 6, message: 'Mật khẩu gồm tối thiêu 6 kí tự' },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                  message: 'Mật khẩu không chứa kí tự đặc biệt và dấu cách',
                },
              ]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Mật khẩu'
                className={styles.form_input}
              />
            </Form.Item>
            <div className={styles.line} />
            <Form.Item
              name='confirmPassword'
              rules={[
                { required: true, message: 'Cần xác nhận lại mật khẩu!' },
                { min: 6, message: 'Mật khẩu gồm tối thiêu 6 kí tự' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    } else
                      return Promise.reject('Mật khẩu xác nhận không khớp');
                  },
                }),
              ]}
              style={{ margin: 0 }}
            >
              <Input.Password
                prefix={<EyeOutlined className={styles.icon} />}
                placeholder='Xác nhận mật khẩu'
                className={styles.form_input}
              />
            </Form.Item>
          </div>
          <Button
            type='primary'
            className={styles.authen_button}
            htmlType='submit'
          >
            Đăng ký tài khoản
          </Button>
        </Form>
        <div className={`${styles.text} ${styles.agree_terms}`}>
          Khi tiếp tục, bạn được coi là đã đồng ý với
        </div>
        <div className={`${styles.text} ${styles.open_terms_btn}`}>
          Điều khoản sử dụng
        </div>
        <div className={`${styles.text} ${styles.signup_link}`}>
          Chưa có tài khoản?&nbsp;
          <Link to='/login'>Đăng nhập</Link>
        </div>
      </div>
    </div>
  );
};
