import { MenuItem } from '@mui/material';
import styles from '../../layout.module.css';
import { Link } from 'react-router-dom';
import { Avatar } from 'Components';
import { Circle } from '@mui/icons-material';
import { StreamFollowerType } from 'constant/enum';
import { getTextWithMaxLength } from 'utils';
import { NotificationInfo } from '../type';

interface NotiItemPropType {
  handleSeeNoti: () => void;
  noti: NotificationInfo;
  handleCloseMenu: () => void;
  isNormalNoti?: boolean;
}

enum NotiType {
  CREATE = 1,
  APPROVE = 2,
  REJECT = 3,
  EXECUTE = 4,
  SEND_COMMENT = 5,
}

enum ChatNotiType {
  STREAM_CHAT = 1,
}

const menu_item_sx = {
  padding: 0,
  whiteSpace: 'unset',
  minWidth: '200px',
  fontFamily: 'Quicksand',
  fontWeight: '600',
  borderRadius: '7px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: '#141D49',

  '&:hover': {
    backgroundColor: '#dddddd',
  },
};

export const NotiItem = (props: NotiItemPropType) => {
  const { handleSeeNoti, handleCloseMenu, noti, isNormalNoti = true } = props;

  const getActionWord = () => {
    switch (noti.type) {
      case NotiType.CREATE:
        return 'tạo';
      case NotiType.APPROVE:
        return 'duyệt';
      case NotiType.REJECT:
        return 'từ chối';
      case NotiType.EXECUTE:
        return 'thực hiện';
      case NotiType.SEND_COMMENT:
        return (
          <>
            thảo luận "
            <span style={{ fontWeight: 600 }}>
              {getTextWithMaxLength(noti.sub_content || '', 30)}
            </span>
            " trong
          </>
        );
    }
  };

  const getChatActionWord = () => {
    switch (noti.type) {
      case ChatNotiType.STREAM_CHAT:
        return (
          <>
            trao đổi "
            <span style={{ fontWeight: 600 }}>
              {getTextWithMaxLength(noti.sub_content || '', 30)}
            </span>
            " trong
          </>
        );
    }
  };

  const getNotiLink = () => {
    const urlPath =
      noti.stream_type === StreamFollowerType.PROPOSAL
        ? 'proposal-detail'
        : 'request-detail';

    if (
      isNormalNoti &&
      [
        NotiType.APPROVE,
        NotiType.REJECT,
        NotiType.EXECUTE,
        NotiType.SEND_COMMENT,
      ].includes(noti.type)
    ) {
      return `/${urlPath}/${noti.root_id}?type=${noti.type}&relateId=${noti.stream}`;
    }

    return `/${urlPath}/${noti.root_id}`;
  };

  const getChatNotiLink = () => {
    const urlPath =
      noti.stream_type === StreamFollowerType.PROPOSAL
        ? 'proposal-detail'
        : 'request-detail';

    return `/${urlPath}/${noti.root_id}?showChat=true`;
  };

  return (
    <MenuItem onClick={handleCloseMenu} sx={menu_item_sx}>
      <Link
        className={`${styles.noti_item} ${styles.menu_item}`}
        to={isNormalNoti ? getNotiLink() : getChatNotiLink()}
        onClick={handleSeeNoti}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={noti.creator.avatar}
            style={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              marginRight: 10,
            }}
          />
          <div>
            <span style={{ fontWeight: 700 }}>{noti.creator.name}</span> đã{' '}
            {isNormalNoti ? getActionWord() : getChatActionWord()} "
            <span style={{ fontWeight: 600 }}>
              {getTextWithMaxLength(noti.content, 50)}
            </span>
            "
          </div>
        </div>
        <div style={{ marginLeft: 5 }}>
          {noti.status ? (
            <div style={{ width: 12 }} />
          ) : (
            <Circle style={{ fontSize: 12, color: '#1D72D8' }} />
          )}
        </div>
      </Link>
    </MenuItem>
  );
};
