import { DomainOutlined, PeopleAltOutlined } from '@mui/icons-material';
import { MenuItemType } from './type';

export enum MenuItemKey {
  ALL = 'all',
  DEPARTMENT = 'department',
  GROUP = 'group',
  DEACTIVATE = 'deactivate',
}

export const defaultMenuItems: MenuItemType[] = [
  {
    key: MenuItemKey.ALL,
    icon: <PeopleAltOutlined />,
    label: 'Tất cả',
  },
  {
    key: MenuItemKey.DEPARTMENT,
    icon: <DomainOutlined />,
    label: 'Phòng ban',
    children: [],
  },
  {
    key: MenuItemKey.GROUP,
    icon: <DomainOutlined />,
    label: 'Group',
    children: [],
  },
  {
    key: MenuItemKey.DEACTIVATE,
    icon: <PeopleAltOutlined />,
    label: 'Đã nghỉ',
  },
];
