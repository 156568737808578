import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './layout.module.css';
import Header from './Header';
import { NavigationTree } from './NavigationTree';
import { useAppContext } from 'Context/AppContext';
import { Homeless } from 'Pages';
import { PageLoading } from 'Components';

interface MainLayoutProps {
  children: React.ReactElement;
  navKey?: string;
  subNavKey?: string;
  subSubNavKey?: string;
}

export default function MainLayout({
  children,
  navKey,
  subNavKey,
  subSubNavKey,
}: MainLayoutProps) {
  const navigate = useNavigate();
  const { orgList, currentOrg } = useAppContext();

  useEffect(() => {
    if (!localStorage.getItem('email')) navigate('/login');
  }, [navigate]);

  if (!localStorage.getItem('email')) return <div></div>;
  if (!orgList) return <PageLoading />;

  return (
    <div className={styles.main_layout}>
      <Header />
      <div className={styles.behind_header} />
      <NavigationTree
        navKey={navKey}
        subNavKey={subNavKey}
        subSubNavKey={subSubNavKey}
      />
      <div className={styles.content}>
        {currentOrg ? children : <Homeless />}
      </div>
    </div>
  );
}
