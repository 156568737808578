export const testImage = (url: string, timeoutT: number) => {
  return new Promise(function (resolve, reject) {
    let timeout = timeoutT || 5000;
    let timer: any;
    let img = new Image();
    img.onerror = img.onabort = function () {
      clearTimeout(timer);
      reject(false);
    };
    img.onload = function () {
      clearTimeout(timer);
      resolve(true);
    };
    timer = setTimeout(function () {
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = '//!!!!/test.jpg';
      reject(false);
    }, timeout);
    img.src = url;
  });
};
