import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelIcon from '@mui/icons-material/Cancel';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { HorizontalLine, VerticalLine } from 'Components';
import { MemberInfo } from './MemberInfo';
import { StreamInfoDataType } from './type';

interface StreamItemPropType extends StreamInfoDataType {
  isSelected: boolean;
  isTheLast: boolean;
  select: () => void;
}

export const StreamItemInTimeline = (props: StreamItemPropType) => {
  const {
    updateAt,
    createAt,
    description,
    creator,
    status,
    isTheLast,
    isSelected,
    select,
  } = props;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ fontWeight: 600, width: 50 }}>
        {moment(updateAt || createAt).format('DD/MM')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {status ? (
          <CheckCircleIcon style={{ fill: '#07bc0c', fontSize: 15 }} />
        ) : (
          <RadioButtonUncheckedIcon style={{ fontSize: 15 }} />
        )}
        <VerticalLine />
      </div>
      <div
        style={{
          width: 'calc(100% - 75px)',
          marginLeft: 10,
          fontWeight: isSelected ? 700 : 500,
          cursor: 'pointer',
        }}
        onClick={select}
      >
        <div>
          <MemberInfo {...creator} />
        </div>
        <div style={{ margin: '15px 0' }}>{description}</div>
        <HorizontalLine style={{ marginBottom: isTheLast ? 70 : 20 }} />
      </div>
    </div>
  );
};
