export const card_sx = {
  color: '#141D49',
  padding: '10px',
  width: '300px',
  cursor: 'pointer',
  border: 'solid 1px #cacaca',
  borderRadius: '10px',
  boxShadow: 'none',
  backgroundColor: '#fff',

  // '&:hover': {
  //   boxShadow:
  //     '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  // },
};

export const grid_item_sx = {
  '@media (max-width: 480px)': {
    margin: 'auto',
  },
};
