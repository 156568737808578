import { useAppContext } from 'Context/AppContext';
import { StreamActionPropType } from '..';
import { ButtonGreen, DisableButtonGreenWrapper } from './style';
import { completeEndStreamTask } from './action';

export const CompleteStreamTaskAction = (props: StreamActionPropType) => {
  const { canUpdate, optionValue, streamId } = props;
  const { currentOrg } = useAppContext();

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã thưc hiện' />;
  } else if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chưa thực hiện' />;
  } else {
    return (
      <ButtonGreen
        onClick={() =>
          completeEndStreamTask({
            value: 1,
            title: 'Xác nhận hoàn thành',
            streamId,
            currentOrg,
          })
        }
      >
        Hoàn thành
      </ButtonGreen>
    );
  }
};
