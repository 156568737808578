import { Card, CircularProgress, Grid } from '@mui/material';
import { Row } from 'antd';
import { ResponseDataType, getRequest } from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import styles from '../stream.module.css';
import { ConvertDatetime } from 'utils';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, CreateStreamButton, HorizontalLine } from 'Components';
import { useAppContext } from 'Context/AppContext';
import {
  GroupInfoDataType,
  StreamFollowInfoDataType,
  StreamInfoListDataType,
} from './type';
import { EndStreamFollowerStatus } from 'constant/enum';

const enum ListType {
  PROPOSAL = 1,
  REQUEST = 2,
}

const LIST_INFO = {
  [ListType.PROPOSAL]: {
    vi: 'đề xuất',
    en: 'proposal',
  },
  [ListType.REQUEST]: {
    vi: 'đề nghị',
    en: 'request',
  },
};

export const ProcessingStreamList = (props: { getAll?: boolean }) => {
  const { getAll = false } = props;
  const { currentOrg } = useAppContext();
  const [streamList, setStreamList] = useState<
    StreamFollowInfoDataType[] | undefined
  >();
  const [groupList, setGroupList] = useState<GroupInfoDataType[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const location = useLocation();
  const listType = location.pathname.includes('proposal-list')
    ? ListType.PROPOSAL
    : ListType.REQUEST;

  useEffect(() => {
    const GetStreamList = async () => {
      const response: ResponseDataType<StreamInfoListDataType> =
        await getRequest(
          getAll
            ? `/stream/${currentOrg}/get-all-stream?type=${listType}&endStatus=${EndStreamFollowerStatus.IN_PROGRESS}`
            : `/stream/${currentOrg}/list-stream-is-following?type=${listType}&endStatus=${EndStreamFollowerStatus.IN_PROGRESS}`
        );

      if (response.code) {
        toast.error(response.message);
      } else {
        setStreamList(response.data?.streamInfoList || []);
        setGroupList(response.data?.groupList || []);
      }
    };

    GetStreamList();
  }, [currentOrg, listType, getAll]);

  const filterStreamList = () => {
    if (!streamList) return [];

    if (!selectedGroup) return streamList;

    return streamList?.filter((stream) =>
      stream.groupList.includes(selectedGroup)
    );
  };

  return (
    <div className={styles.stream_list_page}>
      <Row align='bottom' className={styles.page_title}>
        <div>Danh sách {LIST_INFO[listType].vi}</div>
        <Link
          to={
            getAll
              ? `/all-org-${LIST_INFO[listType].en}-list/history`
              : `/${LIST_INFO[listType].en}-list/history`
          }
          className={styles.link_in_title}
        >
          Xem lịch sử
        </Link>
      </Row>
      {streamList ? (
        <>
          <div>
            <Row>
              {groupList.map((group, index) => (
                <div
                  onClick={() =>
                    setSelectedGroup((prev) =>
                      prev && prev === group.id ? undefined : group.id
                    )
                  }
                  key={index}
                  className={styles.group_filter_label}
                  style={
                    selectedGroup === group.id
                      ? { borderBottomColor: '#1968FC' }
                      : {}
                  }
                >
                  {group.name}
                </div>
              ))}
            </Row>
            <HorizontalLine
              weight='6f'
              style={{ marginTop: -2, marginBottom: 40 }}
            />
          </div>
          <Grid container spacing={3}>
            {filterStreamList().map((stream, index) => (
              <Grid key={index} item style={{ width: 400 }}>
                <Link to={`/${LIST_INFO[listType].en}-detail/${stream.id}`}>
                  <Card
                    className={styles.stream_card}
                    sx={{ opacity: stream.isDone ? 0.4 : 1 }}
                  >
                    <div className={styles.stream_card_description}>
                      {stream.description}
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 700, marginBottom: 5 }}
                    >
                      Tiến trình: {stream.numberOfEnd}/{stream.numberOfStep}
                    </div>
                    <Row justify='space-between' align='middle'>
                      <Row align='middle'>
                        <Avatar
                          src={stream.creatorAvatar}
                          style={{ height: 25, width: 25, borderRadius: '50%' }}
                        />
                        <div style={{ marginLeft: 10, fontWeight: 600 }}>
                          {stream.creatorName}
                        </div>
                      </Row>
                      <div>{ConvertDatetime(stream.createAt)}</div>
                    </Row>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <div
          style={{
            marginTop: 50,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={50} sx={{ color: '#141d49' }} />
        </div>
      )}
      <CreateStreamButton />
    </div>
  );
};
